nav#nav {
    position: fixed;
    z-index: 100;
    top: 40px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);

    .nav-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        background: #1D1D1D;
        padding: 8px 16px;
        padding-right: 8px;
        backdrop-filter: blur(16px);
    }

    .nav-links {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 20px;
        gap: 15px;

        border-radius: 32px;

        a {
            text-decoration: none;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;

            display: flex;
            align-items: center;

            color: #848484;
            position: relative;

            &:hover {
                color: #fff;
            }

            &.active {
                color: #fff;
                background: #010314;
                border: 1px solid rgba(255, 255, 255, 0.15);
            }

            span {
                position: absolute;
                top: -8px;
                left: 60%;
                text-transform: uppercase;
                padding: 0px 4px;
                background: purple;
                white-space: nowrap;
                border-radius: 100px;
                display: block;
                margin-left: 4px;
                font-size: 8px;
            }
        }
    }
}

.navContainer {
    border-radius: 9px;
    background: rgba(white, 0.75);
    backdrop-filter: blur(8px);
    
    position: fixed;
    top: 54px;
    z-index: 999999999;
    width: 90%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 12px 0px;
    position: fixed;
    z-index: 100;
    top: 40px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);

    @media only screen and (max-width: 600px) {
        width: 100%;
        max-width: 100vw;
        top: 0;
      }
}

.shrink {
    background: rgba(white, 0.75);
    width: 600px;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.03), 0px 24px 32px -8px rgba(0, 0, 0, 0.07);
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);

    ul {
        width: 0;
        height: 0;
        transform: scale(0);
        opacity: 0;
    }
    
    @media only screen and (min-width: 780px) {
        &:hover {
            width: 100%;
            ul {
                width: auto;
                display: flex;
                transform: scale(1);
                opacity: 1;
            }
        }
      }

    @media only screen and (max-width: 600px) {
        width: 100%;
        max-width: 100vw;
      }
}
